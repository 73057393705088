const customConfig = {
  deviceEmailSearchMsg: "Enter Email Id",
  deviceMobileSearchMsg: "Enter Mobile Number",
  deviceInvalidMsg: "Invalid request",
  authMsg: "Username or Password is incorrect",
  serverDownMsg: "Service Temporarily Unavailable",
  customerSupportRoleErrorMsg: "You must have Customer Support role to login",
  deviceModelUpdatedMsg: "Device Model Updated",
  enterSearchTextMsg: "Please enter search text",
  enterDeviceIdMsg: "Please enter Device Id",
  enterMacAddressMsg: "Please enter Mac Address",
  enterProductSerialNumMsg: "Please enter Product Serial No.",
  invalidModelNumberMsg: "Please enter model number",
  enterModelNumberMsg: "Please enter model id",
  deviceModelCreatedMsg: "Device Model Created",
  noDataMsg: "No data available",
  noOnboardMsg: "Device not onboarded",
  roleCustomerSupport: "CUSTOMERSUPPORT",
  roleCustomerSupportL1: "CUSTOMERSUPPORTL1",
  roleCustomerSupportL2: "CUSTOMERSUPPORTL2",
  roleCustomerSupportL3: "CUSTOMERSUPPORTL3",
  roleCustomerSupportL3Special: "CUSTOMERSUPPORTL3SPECIAL",
  roleCustomerSupportL4: "CUSTOMERSUPPORTL4",
  roleProductManager: "PRODUCTMANAGER",
  deviceSearchByDeviceId: "deviceId",
  deviceSearchByMacAddress: "macAddress",
  deviceSearchByProductSerialNumber: "productSerialNumber",
  decviceCategoryAC: "AC",
  deviceCategoryCAC: "CAC",
  deviceCategoryIAC: "IAC",
  decviceCategoryFANCONTROLLER: "FANCONTROLLER",
  decviceCategoryANCHOR: "ANCHOR",
  decviceCategoryANCHORFAN: "ANCHORFAN",
  decviceCategoryVDP: "VDP",
  decviceCategoryREF: "REF",
  decviceCategoryWM: "WM",
  decviceCategoryROMA: "ROMA",
  decviceCategoryFAN: "FAN",
  deviceCategorySWITCHES: "SWITCHES",
  deviceCategoryROMASWITCHES: "ROMASWITCHES",
  supportURL: "https://www.panasonic.com/in/miraie/support",
  downloadErrorMsg: "Error occured while download",
  captchaErrorMsg: "Recaptcha requires verification",
  sessionExpireMsg: "Your session has expired please login again",
  logoutFailMsg: "Logout fail",
  confirmOffboard:
    "Are you sure you wish to offboard this device? This cannot be reversed.",
  confirmProccedWithoutProdSerial:
    "Please update Product Serial Number for specific details. Do you want to proceed without update?",
  noServiceDataMsg: "Service data not available",
  noWarrantyDataMsg: "Warranty data not available",
  noServiceRequestMsg: "Service requests are not available",
  noWarrantyRecordsMsg: "Warranty records are not available",
  productMetricsRefreshHour: 2,
  noProductMetricsMsg: "Product Metrics not available",
  productMetricsTitle1: "Onboarded ACs by Date",
  productMetricsFileName1: "onboardedACsByDate.jpg",
  productMetricsTitle2: "Onboarded ACs by Month",
  productMetricsFileName2: "onboarded_ACs_by_month.jpg",
  productMetricsTitle3: "Monthly new connections to Alexa and Google Home",
  productMetricsFileName3: "AlexaGoogleHome.jpg",
  productMetricsTitle4: "Average number of users controlling MirAIe appliances every hour",
  productMetricsFileName4: "hourly.jpg",
  productMetricsTitle5: "Frequency of active users across a week",
  productMetricsFileName5: "weekly.jpg",
  productMetricsTitle6: "Active controllers usage every hour of the day",
  productMetricsFileName6: "hour_channel_distribution.jpg",
  productMetricsTitle7: "Daily count of controllers used by users",
  productMetricsFileName7: "date_controller.jpg",
  productMetricsTitle8: "Parameters controlled by users across hour of the day",
  productMetricsFileName8: "param_distribution.jpg",
  productMetricsTitle9: "Daily active devices by category",
  productMetricsFileName9: "device_category_dist.jpg",
  productMetricsTitle10: " Daily count of active users and devices connected",
  productMetricsFileName10: "active_device_users.jpg",
  productMetricsTitle11: "Daily active users by category",
  productMetricsFileName11: "user_category_dist.jpg",
  productMetricsTitle12: "Offboarded ACs by Month",
  productMetricsFileName12: "offboarded_ACs_by_month.jpg",
  productMetricsTitle13: "Onboarded Non-AC products by Month",
  productMetricsFileName13: "onboarded_non_ACs_by_month.jpg",
  noFotaJobMsg: "No MQTT FOTA jobs exist currently.",
  confirmFotaJobDelete: "Are you sure you wish to delete this job?",
  fotaJobNameNeededMsg: "Please add Job Name",
  fotaJobEndDateNeededMsg: "Please add End Date",
  fotaJobWeekDayNeededMsg: "Please select Week Day(s)",
  fotaJobDefaultDurationInDays: 15,
  specialCharsNotAllowed: "Special character not allowed in Job Name",
  roleCloudAdmin: "CLOUDADMIN",
  roleFactoryAdmin: "FACTORYADMIN",
  matterChipType: ["ESPWIFI", "ESPWIFIBLE"],
  matterFactoryChipTypeFLWM: ["ASRWIFI", "ASRWIFIBLE"],
  matterChipTypeFLWM: ["ESPWIFI", "ASRWIFI", "ASRWIFIBLE"],
  deviceCategoryODMFRONTLOADWM: "ODMFRONTLOADWM",
  roleFieldOperator: "FIELDOPERATOR",
  roleLineTestUser: "LINETESTUSER",
  mfaInvalid: "Inavlid OTP. Please try again.",
  addMfaDeviceError: "Something went wrong. Please try again.",
  mfaSyncError: "Authentication sync failed. Try again.",
  mfaSyncSuccess: "Your 2-Step Authentication setup is complete.",
  mfaCodeInstructions: [
    "Open your authenticator app on mobile (e.g., Google Authenticator, Authy).",
    "Wait for the app to generate the first 6-digit code.",
    "Enter the first code in the 'Code 1' field.",
    "Wait for the app to refresh and generate a new code (usually takes 30 seconds).",
    "Enter the new code in the 'Code 2' field.",
    "Click 'Submit' to complete the setup."
  ],
  mfaSetupInstuctions: [
    "Download an authenticator app (e.g., Google Authenticator, Authy) on your mobile device from the Play Store or App Store.",
    "Open the authenticator app and select the option to add a new account (often represented by a + icon).",
    "Choose to scan a QR code, and use your phone's camera to scan the QR code displayed on your screen.",
    "Once the QR code is scanned, the app will generate a 6-digit code. Enter this code when prompted in the next step.",
  ],
  mfaValidationInstructions: [
    "Open your authenticator app on mobile (e.g., Google Authenticator, Authy).",
    "Wait for the app to generate the first 6-digit code.",
    "Enter the code in the Code field.",
    "Click 'Validate' to complete the Login process."
  ]
};

export default customConfig;
