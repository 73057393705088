import React, { useState } from 'react';
import { Menu, MenuItem, IconButton, ListItemIcon, Switch, ListItemText } from '@material-ui/core';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import PersonIcon from '@material-ui/icons/Person';
import HelpIcon from '@material-ui/icons/Help';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import customConfig from '../../config';

const UserProfileMenu = ({ logout, isMfaEnabled, toggleMfa }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleViewProfile = () => {
    handleMenuClose();
  };

  const handleSupport = () => {
    handleMenuClose();
    window.open(customConfig.supportURL, '_blank');
  };

  const handleToggleMfa = () => {
    toggleMfa(!isMfaEnabled);
  };

  const handleLogout = () => {
    handleMenuClose();
    logout();
  };

  return (
    <div className='profile-icon'>
      <IconButton onClick={handleMenuOpen} style={{ color: 'white', backgroundColor: 'transparent' }} >
        <AccountCircleIcon fontSize="large" />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        getContentAnchorEl={null}
      >
        <MenuItem onClick={handleViewProfile}>
          <ListItemIcon>
            <PersonIcon fontSize="small" />
          </ListItemIcon>
          View Profile
        </MenuItem>

        <MenuItem onClick={handleSupport}>
          <ListItemIcon>
            <HelpIcon fontSize="small" />
          </ListItemIcon>
          Support
        </MenuItem>
        <MenuItem onClick={handleLogout}>
          <ListItemIcon>
            <ExitToAppIcon fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    </div>
  );
};

export default UserProfileMenu;