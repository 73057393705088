import React, { Fragment, useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { login, logout, addMfaDevice } from "../../action/auth";
import { getTenantInfo } from "../../action/tenant";
import loginLogo from "../../../src/assets/black_logo.png";
import Alert from "../layout/Alert";
import UseForm from "./UseForm";
import validate from "./LoginFormValidationRules";
import ReCAPTCHA from "react-google-recaptcha";
import customConfig from "../../config";
import CircularProgress from "@material-ui/core/CircularProgress";
import env from "../../env";
import MfaQRcode from "./mfaQRcode";
import MfaValidate from "./mfaValidationCode"

// const CLIENT_ID = env.GET_CLIENT_ID();
const SCOPE = process.env.REACT_APP_SCOPE;

const Login = ({ login, isAuthenticated, logout, getTenantInfo, addMfaDevice, qrCodeUrl, mfaToken }) => {
  const [recaptchaValue, setRecaptchaValue] = useState();
  const [showError, setShowError] = useState(false);
  const { handleSubmit, InputOnFocus, handleChange, values, errors } = UseForm(
    loginForm,
    validate
  );

  const [isLoading, setIsLoading] = useState(false);
  const [tenantData, setTenantData] = useState({});
  const [tmsAPIError, setTmsAPIError] = useState(false);
  const [showQrCode, setShowQrCode] = useState(false);
  const [mfavalidationCode, setMfaValidationCode] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    getTenantInfo()
      .then((res) => {
        setIsLoading(false);
        setTenantData(res);
      })
      .catch((err) => {
        setIsLoading(false);
        setTmsAPIError(true);
      });
  }, []);

  useEffect(() => {
    if (qrCodeUrl) {
      setShowQrCode(true);
    }
    if (mfaToken) {
      setMfaValidationCode(true)
    }
  }, [qrCodeUrl, mfaToken]);

  let captcha;
  function loginForm() {
    if (recaptchaValue) {
      login(
        values.mobile,
        values.password,
        env.GET_CLIENT_ID(),
        SCOPE,
        recaptchaValue
      ).then((res) => {
        if (res.data.accessToken) {
          addMfaDevice(res.data.accessToken)
        }
      })
      .catch((err) => console.error("Login Error: ", err));
      setTimeout(function () {
        resetCaptcha();
      }, 2000);
    } else {
      setShowError(true);
    }
  }
  const setCaptchaRef = (ref) => {
    if (ref) {
      return (captcha = ref);
    }
  };
  const resetCaptcha = () => {
    // maybe set it till after is submitted
    captcha.reset();
  };
  const onChangeRecaptcha = (value) => {
    setRecaptchaValue(value);
    setShowError(false);
  };

  const errorCallback = (value) => {
    //console.log(value,'===error callback')
  };

  const onExpiredCallback = (response) => {
    setRecaptchaValue("");
    //console.log(response,'===onExpired');
  };

  if (isAuthenticated) {
    return <Redirect to="/dashboard" />;
  }

  if (showQrCode && qrCodeUrl) {
    return (
      <div style={{ display: "grid", placeItems: "center", height: "100vh" }}>
        <MfaQRcode tenantData = {tenantData}/>
      </div>
    );
  }

  if (mfaToken && mfavalidationCode) {
    return (
      <div style={{ display: "grid", placeItems: "center", height: "100vh" }}>
        <MfaValidate tenantData = {tenantData}/>
      </div>
    )
  }
  if (isLoading) {
    return (
      <div className="login-wrapper">
        <div className="login-box">
          <div
            style={{
              display: "grid",
              placeItems: "center",
              height: "100%",
            }}
          >
            <CircularProgress size="5em" style={{ color: "#AFB42B" }} />
          </div>
        </div>
      </div>
    );
  }
  if (tmsAPIError) {
    return (
      <div
        style={{
          background: "#f0f0f0",
          display: "grid",
          placeItems: "center",
          width: "100%",
          textAlign: "center",
          paddingTop: "10vh",
        }}
      >
        <div
          style={{
            width: "80%",
            height: "80vh",
            display: "grid",
            placeItems: "center",
            background: "#fcfcfc",
          }}
        >
          <div style={{ paddingBottom: 0 }}>
            <p
              style={{
                fontWeight: "bold",
                color: "#B8B8B8",
              }}
            >
              <span style={{ fontSize: 100, color: "grey" }}>500 </span>
              <span style={{ fontSize: 60, color: "#bd2d19" }}>
                Server Error
              </span>
            </p>
          </div>
          <div
            style={{
              fontSize: 25,
              textAlign: "left",
            }}
          >
            <p>Oops, something went wrong.</p>
            <br></br>
            <p>
              Try to refresh this page or feel free to contact us if the problem
              persists
            </p>
          </div>
        </div>
      </div>
    );
  }
  return (
    <Fragment>
      <div className="login-wrapper">
        <div className="login-box">
          <div className="form-box">
            <div className="row mb-0">
              <Alert />
              <div className="col s12 center-align">
                <img
                  src={tenantData.tenantLogoUrl}
                  alt="Logo"
                  className="logo-outer"
                // style={{ width: "250px", height: "55px" }}
                />
              </div>
            </div>
            <form className="form" onSubmit={handleSubmit} noValidate>
              <div className="row">
                <div className="input-field p-0 col s12">
                  <label htmlFor="mobile">User Name</label>
                  <input
                    type="text"
                    name="mobile"
                    autoComplete="off"
                    value={values.mobile || ""}
                    onChange={handleChange}
                    onInput={InputOnFocus}
                    required
                    placeholder="Enter User Name"
                    className={`add-input xl ${errors.mobile && "is-danger"}`}
                  />
                  {errors.mobile && (
                    <p
                      className="help is-danger error-message "
                      style={{ marginTop: "10px" }}
                    >
                      {errors.mobile}
                    </p>
                  )}
                </div>
              </div>
              <div className="row mb-0">
                <div className="input-field p-0 col s12">
                  <label htmlFor="password">Password</label>
                  <input
                    type="password"
                    name="password"
                    value={values.password || ""}
                    onChange={handleChange}
                    onInput={InputOnFocus}
                    minLength="6"
                    placeholder="Enter Password"
                    className={`add-input xl ${errors.password && "is-danger"}`}
                    required
                  />
                  {errors.password && (
                    <p className="help is-danger error-message">
                      {errors.password}
                    </p>
                  )}
                </div>
              </div>

              <div className="capcha-box">
                <ReCAPTCHA
                  sitekey={process.env.REACT_APP_CAPTCHA_API_KEY}
                  onChange={onChangeRecaptcha}
                  onErrored={errorCallback}
                  onExpired={onExpiredCallback}
                  ref={(r) => setCaptchaRef(r)}
                />
                {showError && (
                  <p
                    className="help is-danger error-message "
                    style={{ marginTop: "10px" }}
                  >
                    {customConfig.captchaErrorMsg}
                  </p>
                )}
              </div>

              <div className="row mb-0">
                <div className="input-field p-0 col s12">
                  <button
                    className="btn add-btn xl col s12  mTop20"
                    type="submit"
                    name="action"
                  >
                    Sign In{" "}
                  </button>
                </div>
              </div>
            </form>
          </div>

          {/* <div className="white-text center font-13">
            Copyright © 2020 Panasonic India Pvt Ltd.
          </div> */}
        </div>
        {/* <div className="footer login font-13  font-300i">
          Designed and Developed by Panasonic India Innovation Center
        </div>{" "} */}
      </div>
    </Fragment>
  );
};
Login.propTypes = {
  login: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
  qrCodeUrl: PropTypes.string,
  addMfaDevice: PropTypes.func.isRequired,
  mfaToken: PropTypes.string
};
const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  qrCodeUrl: state.auth.qrCodeUrl,
  mfaToken: state.auth.mfaToken
});

const mapDispatchToProps = (dispatch) => {
  return {
    login: login(dispatch),
    logout: logout(dispatch),
    getTenantInfo: getTenantInfo(dispatch),
    addMfaDevice: (accessToken) => dispatch(addMfaDevice(accessToken)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Login);
