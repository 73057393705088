import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { QRCodeSVG } from "qrcode.react";
import Alert from '../layout/Alert';
import CodeInput from './mfaCode';
import customConfig from "../../config";

const MfaQrCode = ({ qrCodeUrl, tenantData }) => {
  const [showInstructions, setShowInstructions] = useState(false);
  const [showCodes, setShowCodes] = useState(false);

  const handleInstructionsClick = (e) => {
    e.preventDefault();
    setShowInstructions(true);
  };

  const closeInstructions = () => {
    setShowInstructions(false);
  };

  const handleContinue = () => {
    setShowCodes(true);
  };

  return (
    <Fragment>
      <div className="login-wrapper">
        <div className="login-box">
          <div className="form-box">
            <div className="row mb-0">
              <div className="col s12 center-align">
                <img
                  src={tenantData.tenantLogoUrl}
                  alt="Logo"
                  className="logo-outer"
                />
              </div>
            </div>
            {showInstructions && (
              <div className="modal">
                <div className="modal-content">
                  <span className="close" onClick={closeInstructions}>&times;</span>
                  <h2>Set up 2-Step Authentication</h2>
                  <ol>
                  {customConfig.mfaSetupInstuctions.map((instruction, index) => (
                   <li key={index}>{instruction}</li> ))
                  }  
                  </ol>
                </div>
              </div>
            )}
            {!showCodes ? (
              <>
                <h2 className="center-align" style={{ padding: '20px 0' }}>
                  Scan this QR code to set up 2-Step Authentication
                </h2>
                <div className="row mb-0">
                  <div className="input-field p-0 col s12 center-align">
                    {qrCodeUrl ? (
                      <QRCodeSVG value={qrCodeUrl} size={256} />
                    ) : (
                      <p>Loading QR Code...</p>
                    )}
                    <div style={{ marginTop: '10px' }}>
                      <a href="#" onClick={handleInstructionsClick} style={{ color: 'blue' }}>Need help?</a>
                    </div>
                  </div>
                </div>
                <div className="row mb-0">
                  <div className="input-field p-0 col s12">
                    <button
                      onClick={handleContinue}
                      className="btn add-btn xl col s12 mTop20"
                      type="button"
                    >
                      Continue
                    </button>
                  </div>
                </div>
              </>
            ) : (
              <CodeInput />
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

MfaQrCode.propTypes = {
  qrCodeUrl: PropTypes.string.isRequired,
  tenantData: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  qrCodeUrl: state.auth.qrCodeUrl,
});

export default connect(mapStateToProps)(MfaQrCode);
